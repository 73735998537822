<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const { weeklyCashbackData, hasWeeklyReward, claimIsActive, durationLeftDisplay, claimWeeklyReward } = useStatus();
const loginGuard = useLoginGuard();
const router = useRouter();

const handlePlayClick = () =>
	loginGuard({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: "vip_club_reward"
			});

			router.push("/issues/all-games/");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});

const handleClaimClick = () =>
	loginGuard({
		success: () => {
			if (!claimIsActive.value) {
				return;
			}
			claimWeeklyReward();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
</script>
<template>
	<div class="wrapper">
		<div class="icon">
			<NuxtImg src="nuxt-img/vip/reward.png" width="61" height="61" format="webp" loading="lazy" alt="weekly-reward" />
		</div>
		<div class="content">
			<AText class="text-cannes" variant="toledo" :modifiers="['bold', 'uppercase']" as="div">
				{{ t("Weekly Reward") }}
			</AText>
			<div class="weekly-reward">
				<div class="weekly-reward-item">
					<AText class="text-coimbatore" variant="toledo" :modifiers="['bold', 'center']"
						>{{ weeklyCashbackData?.currentReward }}%</AText
					>
					<AText class="text-cannes" variant="texas" :modifiers="['bold', 'center', 'uppercase']">{{
						t("Current")
					}}</AText>
				</div>
				<ASvg v-if="weeklyCashbackData?.nextReward" name="icons/vip/reward-progress" filled />

				<div v-if="weeklyCashbackData?.nextReward" class="weekly-reward-item">
					<AText class="text-caracas" variant="toledo" :modifiers="['bold', 'center']"
						>{{ weeklyCashbackData.nextReward }}%</AText
					>
					<AText class="text-caracas" variant="texas" :modifiers="['bold', 'center', 'uppercase']">{{
						t("next status")
					}}</AText>
				</div>
			</div>
		</div>
		<AButton
			v-if="hasWeeklyReward"
			:variant="claimIsActive ? 'primary' : 'secondary'"
			:modifiers="[claimIsActive ? '' : 'disabled']"
			class="btn"
			size="s"
			@click="handleClaimClick"
		>
			<AText>{{ claimIsActive ? "Claim" : durationLeftDisplay }}</AText>
		</AButton>
		<AButton v-else variant="primary" class="btn" size="s" @click="handlePlayClick">
			<AText>{{ t("Play") }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.wrapper {
	cursor: inherit !important;
}

.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}

.btn {
	--a-button-secondary-background: var(--cankaya);
	--a-button-secondary-disabled-background: var(--cankaya);
	--a-button-secondary-disabled-color: var(--cannes);
	--a-button-secondary-disabled-padding: 6.5px;

	&.disabled {
		width: 100px !important;
	}
}

.weekly-reward {
	display: flex;
	align-items: center;
	gap: 8px;
	background: linear-gradient(90deg, rgba(96, 49, 84, 0) 0%, #603154 46.53%, rgba(96, 49, 84, 0) 100%);
	margin-top: 4px;
	padding: 4px 0;

	:deep(svg) {
		width: 27px;
		height: 13px;
		opacity: 0.4;
	}

	.weekly-reward-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>
